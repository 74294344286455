.Brand {
  background: #DDD;
  height: 200px;
}

.LoginCode, .LoginName {
  font-size: 1.25rem;
}

.LoginButton {
  font-size: 1.25rem;
}

header {
  width: 100vw;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.Main {
  min-height: calc(1.2em + 100vh);
}

body {
  background-color: #f7fafc;
}

.SearchInput {
  font-size: 30px;
}

.LoadingBar {
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.5;
}
@keyframes pulsate {
    0% {
        opacity: 0.25;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        opacity: 0.25;
    }
}
